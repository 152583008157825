import { mapActions, mapGetters, mapMutations } from "vuex";
import { ModelListSelect } from "vue-search-select";

export default {
    name: "addresses-list",
    data() {
        return {
            payload: {
                address: "",
                guid: "",
            },
            searchCountryTitle: "",
            addressList: [],
        };
    },
    components: {
        ModelListSelect,
    },
    computed: {
        ...mapGetters({
            user: "profile/user",
            address: "order/addresses",
        }),
    },
    methods: {
        ...mapActions({
            fetchUser: "profile/FETCH_USER_DATA",
            fetchAddress: "order/GET_ADDRESS_LIST",
        }),
        ...mapMutations({
            changeAddressesListPopup: "popups/CHANGE_ADDRESSES_LIST_POPUP",
            saveAddress: "order/SAVE_SELECT_ADDRESS",
        }),
        printSearchText(searchText) {
            this.searchCountryTitle = searchText;
        },
        selectAddress() {
            this.changeAddressesListPopup(false);
            this.payload.status = 0;
            this.saveAddress(this.payload);
        },
    },
    created() {
        document.getElementById("html").classList.add("hide");

        this.addressList = this.address;
    },
};
