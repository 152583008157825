import { mapMutations } from "vuex";

export default {
    name: "instagram-post",
    methods: {
        ...mapMutations({
            changeInstagramPostPopup: "popups/CHANGE_INSTAGRAM_POPUP",
        }),
    },
    created() {
        document.getElementById("html").classList.add("hide");
    },
};
