import axios from "axios";

/**
 * @param {string} uri
 * @param {string} fileName
 * @return {Promise<void>}
 */
export async function downloadFileFrom(uri, fileName) {
    const response = await axios.get(uri, { responseType: "blob" });

    downloadFile(response.data, fileName, response.headers["content-type"]);
}

/**
 * @param {*} content
 * @param {string} fileName
 * @param {string} type
 */
export function downloadFile(content, fileName, type) {
    const blob = new Blob([content], { type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

    URL.revokeObjectURL(link.href);
}
