export default {
    methods: {
        navigateTo(params) {
            this.$router.push({ name: params }).catch(() => {});
            try {
                this.$router.push({ name: params }).catch(() => {});
            } catch (e) {
                console.log(e);
            }
        },
    },
};
