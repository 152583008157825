interface State {
    currentModal: string | null;
    currentModalPayload: any;
}

const state: State = {
    currentModal: null,
    currentModalPayload: null,
};

function openModal(state: State, popupName: string, payload: any = null) {
    state.currentModal = popupName;
    state.currentModalPayload = payload;
}

function closeModal(state: State) {
    state.currentModal = null;
    state.currentModalPayload = null;
}

function changeModal(state: State, popupName: string, status: boolean, payload: any = null) {
    if (status) {
        openModal(state, popupName, payload);
    } else {
        document.getElementById("html")?.classList.remove("hide");
        closeModal(state);
    }
}

export const PopupName = {
    smsPopup: "sms-popup",
    notifyWhenAvailable: "notify-when-available",
    seminarDetails: "seminar-details",
    basket: "basket",
    agentRegister: "agent-register",
    otherRegister: "other-register",
    distributorRegister: "distributor-register",
    successRegisterSeminar: "success-register-seminar",
    seminarRegistration: "seminar-registration",
    successRegisterAgent: "success-register-agent",
    defaultPrices: "default-prices",
    fastBuy: "fast-buy",
    paymentInfo: "payment-info",
    successOrder: "success-order",
    anotherCosmetolog: "another-cosmetolog",
    successRegister: "success-register",
    instagramPopup: "instagram-popup",
    addressesList: "addresses-list",
    restore: "restore",
    reset: "reset",
    formApplication: "form-application",
    registration: "registration",
    login: "login",
    remainings: "remainings",
    seminarInfo: "seminar-info",
    metodistPopup: "metodist-popup",
    certificateProblem: "certificate-problem",
    feedbackModal: "feedback-modal",
};

const getters = {
    currentModal: (state: State) => state.currentModal,
    currentModalPayload: (state: State) => state.currentModalPayload,
};

const actions = {};

const mutations = {
    CLOSE_POPUP(state: State) {
        closeModal(state);
    },
    CHANGE_PHONE_REGISTER_POPUP(state: State, status: boolean) {
        changeModal(state, PopupName.smsPopup, status)
    },
    CHANGE_BASKET_POPUP(state: State, status: boolean) {
        changeModal(state, PopupName.basket, status)
    },
    CHANGE_AGENT_REGISTER_POPUP(state: State, status: boolean) {
        changeModal(state, PopupName.agentRegister, status)
    },
    CHANGE_OTHER_REGISTER_POPUP(state: State, status: boolean) {
        changeModal(state, PopupName.otherRegister, status)
    },
    CHANGE_DISTRIBUTOR_REGISTER_POPUP(state: State, status: boolean) {
        changeModal(state, PopupName.distributorRegister, status)
    },
    CHANGE_SEMINAR_REGISTRATION_POPUP(state: State, status: boolean) {
        changeModal(state, PopupName.seminarRegistration, status)
    },
    SHOW_SUCCESS_SEMINAR(state: State, status: boolean) {
        changeModal(state, PopupName.successRegisterSeminar, status)
    },
    CHANGE_DEFAULT_PRICES_POPUP(state: State, status: boolean) {
        changeModal(state, PopupName.defaultPrices, status)
    },
    CHANGE_FAST_BUY_POPUP(state: State, status: boolean) {
        changeModal(state, PopupName.fastBuy, status)
    },
    CHANGE_LOGIN_POPUP(state: State, status: boolean) {
        changeModal(state, PopupName.login, status);
    },
    CHANGE_ADDRESSES_LIST_POPUP(state: State, status: boolean) {
        changeModal(state, PopupName.addressesList, status);
    },
    CHANGE_REGISTRATION_POPUP(state: State, status: boolean) {
        changeModal(state, PopupName.registration, status);
    },
    CHANGE_RESET_POPUP(state: State, status: boolean) {
        changeModal(state, PopupName.reset, status)
    },
    CHANGE_RESTORE_POPUP(state: State, status: boolean) {
        changeModal(state, PopupName.restore, status)
    },
    CHANGE_INSTAGRAM_POPUP(state: State, status: boolean) {
        changeModal(state, PopupName.instagramPopup, status)
    },
    CHANGE_SUCCESS_REGISTER_POPUP(state: State, payload: any = {}) {
        changeModal(state, PopupName.successRegister, true,  payload)
    },
    CHANGE_COSMETOLOG_POPUP(state: State, status: boolean) {
        changeModal(state, PopupName.anotherCosmetolog, status)
    },
    CHANGE_APPLICATION_FORM_POPUP(state: State, status: boolean) {
        changeModal(state, PopupName.formApplication, status)
    },
    CHANGE_PAYMENT_INFO_POPUP(state: State, status: boolean) {
        changeModal(state, PopupName.paymentInfo, status)
    },
    CHANGE_SHOW_SUCCESS_AGENT_REGISTER(state: State, status: boolean) {
        changeModal(state, PopupName.successRegisterAgent, status)
    },
    CHANGE_REMAININGS_POPUP(state: State, status: boolean) {
        changeModal(state, PopupName.remainings, status)
    },
    CHANGE_SEMINAR_INFO_POPUP(state: State, status: boolean) {
        changeModal(state, PopupName.seminarInfo, status)
    },
    CHANGE_SUCCESS_PAYMENT_POPUP(state: State, payload: any = {}) {
        changeModal(state, PopupName.successOrder, payload.status, payload.payload)
    },
    CHANGE_SEMINAR_DETAILS_POPUP(state: State, payload: any = {}) {
        changeModal(state, PopupName.seminarDetails, payload.status, payload.payload)
    },
    SHOW_NOTIFY_WHEN_AVAILABLE(state: State, payload: any = {}) {
        changeModal(state, PopupName.notifyWhenAvailable, payload.status, payload.payload);
    },
    CHANGE_METODIST_POPUP(state: State, payload: any = {}) {
        changeModal(state, PopupName.metodistPopup, payload.status, payload.payload);
    },
    CHANGE_CERTIFICATE_PROBLEM_POPUP(state: State, payload: any = {}) {
        changeModal(state, PopupName.certificateProblem, payload.status, payload.payload);
    },
    CHANGE_FEEDBACK_MODAL(state: State, payload: any = {}) {
        changeModal(state, PopupName.feedbackModal, payload.status, payload.payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
