import {globalLoader} from '@/helpers/variables';
import {$http} from '@/utils/https'

import type {ActionContext} from "vuex";
import type {RootState} from "@/store";

import userHelpers from "@/helpers/user-helpers";
import {type User, UserTypes} from "@/types/user";
import type {Order} from "@/types/order";
import type {PaginatorData} from "@/types/api";
import type {IUploadFilesPayload, IUserUpdatePayload} from "@/store/modules/types/profile";


const OrderStatusFilter = {
  finished: 'finished',
  in_progress: 'in_progress',
  all: 'all',
}

interface State {
  adminData: any;

  user: User | null;
  updateUserLoading: boolean;
  clientUserData: User | null;
}

const state: State = {
  adminData: null,

  user: null,
  updateUserLoading: false,
  clientUserData: null,
};

const getters = {
  adminData: (state: State) => state.adminData,

  user: (state: State) => state.user,
  updateUserLoading: (state: State) => state.updateUserLoading,
  clientUserData: (state: State) => state.clientUserData,

  isDistributor: (state: State): boolean => userHelpers.checkUserType(state.user, UserTypes.Distributor),
  isProfessional: (state: State): boolean => userHelpers.checkUserType(state.user, UserTypes.Professional),
  isUserWithCode: (state: State): boolean => userHelpers.checkUserType(state.user, UserTypes.Authorized_user),

  canUseBonuses: (state: State): boolean => !!state.user?.canUseBonuses,
};

type PaginatorInfo = { page: number, perPage?: number }

const actions = {
  FETCH_USER_DATA: async ({commit}: ActionContext<State, RootState>) => {
    try {
      const response = await $http.get<{ data: User }>('v1/auth/me');

      commit('SET_USER_DATA', response.data.data);
      // commit('SET_USER_DATA', {
      //   "id": 1533,
      //   "active": true,
      //   "email": "tmid44@gmail.com",
      //   "name": "yaa",
      //   "second_name": "test",
      //   "last_name": "123",
      //   "phone": "+380936890437",
      //   "country": "Ukraine",
      //   "city": "Dnipro",
      //   "work": "",
      //   "certificate_number": "",
      //   "additional_information": "",
      //   "created_at": "2024-08-08",
      //   "bonus_amount": 200,
      //   "can_use_bonuses": true,
      //   "user_type": {
      //     "title": "Professional",
      //     "name": "professional"
      //   },
      //   "code": "",
      //   "individual_discount": 12,
      //   "files": {
      //     "data": []
      //   },
      //   "assign_to": {
      //     "data": null
      //   },
      //   "clients": [],
      //   "type_of_activity": "selectDoctor,selectBeautySalonStudio,selectCosmetologistInjectionNurse",
      //   "social": null,
      //   "type_of_procedures": "all_care,cleaning",
      //   "selling_care": "redemption,official_skin_on_retailer,community_group,online_store,showcase_realization",
      //   "birth_date": "2000-03-25T22:00:00.000000Z",
      //   "work_experience": "to_1",
      //   "ico_number": null,
      //   "vat_number": null
      // });
    } catch (e) {
      throw e;
    } finally {
    }
  },
  UPDATE_USER_DATA: async ({commit}: ActionContext<State, RootState>, payload: IUserUpdatePayload) => {
    try {
      commit('UPDATE_USER_DATA_LOADING', true);
      const response = await $http.put<{ data: User }>('v1/user', payload);
      commit('SET_USER_DATA', response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit('UPDATE_USER_DATA_LOADING', false);
    }
  },
  PROFESSIONAL_ADD_FILES: async ({commit}: ActionContext<State, RootState>, payload: IUploadFilesPayload) => {
    try {
      commit('UPDATE_USER_DATA_LOADING', true);
      const response = await $http.post<{ data: User}>('v1/user/files', payload);
      commit('SET_USER_DATA', response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit('UPDATE_USER_DATA_LOADING', false);
    }
  },
  LINK_WITH_GOOGLE: async ({commit}: ActionContext<State, RootState>, payload: any) => {
    globalLoader(true);
    try {
      const result = await $http.post('v1/oauth/google/link', payload);
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },
  LINK_WITH_FACEBOOK: async ({commit}: ActionContext<State, RootState>, payload: any) => {
    globalLoader(true);
    try {
      const result = await $http.post('v1/oauth/facebook/link', payload);
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },
  UNLINK_WITH_GOOGLE: async ({commit}: ActionContext<State, RootState>, payload: any) => {
    globalLoader(true);
    try {
      const result = await $http.post('v1/oauth/google/unlink');
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },
  UNLINK_WITH_FACEBOOK: async ({commit}: ActionContext<State, RootState>, payload: any) => {
    globalLoader(true);
    try {
      const result = await $http.post('v1/oauth/facebook/unlink');
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },

  GET_CLIENT_ORDER: async ({commit}: ActionContext<State, RootState>, payload: (PaginatorInfo & {
    clientId: number
  })) => {
    try {
      const response = await $http.get<PaginatorData<Order[]>>(`v1/user/${payload.clientId}/orders`, {
        params: {
          page: payload.page,
          perPage: payload.perPage,
        }
      });

      return response.data;
    } catch (e) {
      throw e;
    } finally {
      // ...
    }
  },
  GET_CLIENT_USER_DATA: async ({commit}: ActionContext<State, RootState>, clientId: number | string) => {
    try {
      const responseClient = await $http.get<{ data: User }>(`v1/user/${clientId}`);
      commit('SET_CLIENT_USER_DATA', responseClient.data.data);
    } catch (e) {
      throw e;
    } finally {
      // ...
    }
  },
  GET_CURRENT_ORDERS: async ({commit}: ActionContext<State, RootState>, payload: PaginatorInfo) => {
    try {
      const response = await $http.get<PaginatorData<Order[]>>(`v1/orders`, {
        params: {
          orders_status: OrderStatusFilter.in_progress,
          page: payload.page,
          perPage: payload.perPage || 15
        }
      });

      return response.data;
    } catch (e) {
      throw e;
    } finally {
      // ...
    }
  },
  GET_HISTORY_ORDERS: async ({commit}: ActionContext<State, RootState>, payload: PaginatorInfo) => {
    try {
      const response = await $http.get<PaginatorData<Order[]>>(`v1/orders`, {
        params: {
          orders_status: OrderStatusFilter.finished,
          page: payload.page,
          perPage: payload.perPage || 15
        }
      });

      return response.data;
    } catch (e) {
      throw e;
    } finally {
    }
  },
};

const mutations = {
  setAdminData(state: State, data: any) {
    let currentLinks: Array<any> = [];
    if (!data.resetLinks) {
      currentLinks = state.adminData?.links || [];
    }
    const newLinks: Array<any> = data.links || [];

    // @ts-ignore
    currentLinks.push(...newLinks)

    // unique links by url
    currentLinks = currentLinks.filter((item: any, index: number, self: any) =>
      index === self.findIndex((t: any) => (
        t.url === item.url
      )))

    state.adminData = {
      adminHome: data.adminHome,
      links: currentLinks
    };
  },
  SET_USER_DATA(state: State, user: User) {
    state.user = user;
  },
  SET_CLIENT_USER_DATA(state: State, data: User) {
    state.clientUserData = data;
  },
  UPDATE_USER_DATA_LOADING(state: State, status: boolean) {
    state.updateUserLoading = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
