<template>
    <div class="small-loader">
        <loader></loader>
    </div>
</template>
<script>
export default {
    name: "small-loader",
};
</script>
<style lang="scss">
.small-loader {
    position: relative;
    min-width: 100px;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
        min-height: 25px;

        & > div {
            width: 25px;
            height: 25px;
        }
    }
}
</style>
