import { mapGetters } from "vuex";

export default {
    name: "success-register-agent",
    computed: {
        ...mapGetters({}),
    },
    created() {
        document.getElementById("html").classList.add("hide");
    },
};
