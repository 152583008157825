import Vue from "vue";
import VueTheMask from "vue-the-mask";
import Toasted from "vue-toasted";
import Vuelidate from "vuelidate";
import appLink from "./apilink";

// import VueAnimate from 'vue-animate-scroll';
// import VueFacebookPixel from 'vue-analytics-facebook-pixel'
import "moment/locale/uk";
import "moment/locale/ru";

//use
Vue.use(Toasted, {
    duration: 3000,
    position: "top-right",
});
Vue.use(appLink);
// Vue.use(VueAnimate);
Vue.use(VueTheMask);
Vue.use(Vuelidate);
Vue.use(require("vue-moment"));
// Vue.use(VueFacebookPixel)
