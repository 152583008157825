import { email, minLength, required } from "vuelidate/lib/validators";
import PhoneMaskInput from "vue-phone-mask-input";

import { mapActions, mapGetters, mapMutations } from "vuex";
import validation from "@/mixins/validation";

export default {
    name: "feedback-modal",

    components: {
        PhoneMaskInput
    },

    data() {
        return {
            payload: {
                name: "",
                last_name: "",
                phone: "",
                email: "",
            },
            validationErrors: {},
        };
    },
    mixins: [validation],
    validations: {
        payload: {
            name: {
                required
            },
            last_name: {
                required
            },
            phone: {
                required,
            },
            email: {
                required,
                email
            }
        },
    },
    computed: {
        ...mapGetters({
            product: "card/product",
            isLoading: "order/isLoading",
            fastBuyPopup: "popups/currentModalPayload",
        }),
        phoneErrors() {
            return this.computeErrors("payload", "phone", {
                required: "validationRequired",
            });
        },
        emailErrors() {
            return this.computeErrors("payload", "email", {
                required: "validationRequired",
                email: "validationEmail",
            });
        },
        nameErrors() {
            return this.computeErrors("payload", "name", {
                required: "validationRequired"
            });
        },
        lastNameErrors() {
            return this.computeErrors("payload", "last_name", {
                required: "validationRequired"
            });
        },
    },
    created() {
        document.getElementById("html").classList.add("hide");
    },
    methods: {
        ...mapActions({
            createOrder: "order/CREATE_ORDER",
            storeFeedback: "setting/SEND_FEEDBACK",
        }),
        ...mapMutations({
            changeFastBuyPopup: "popups/CHANGE_FAST_BUY_POPUP",
        }),
        async submit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                try {
                    await this.storeFeedback(this.payload);
                    this.payload.name = "";
                    this.payload.last_name = "";
                    this.payload.phone = "";
                    this.payload.email = "";
                    this.$toasted.success(this.$t("feedbackSent"));
                } catch (e) {
                    this.$toasted.error(this.$t("feedbackSendingError"));
                } finally {
                    this.$emit("close-popup");
                }
            }
        },
    },
};
